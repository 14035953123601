<template>
    <v-container fluid>
        <v-dialog
            v-model="testGroupDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <app-form
                :key="key"
                v-model="editedTestGroup"
                :schema="getSchema('TestGroups')"
                :filter="testGroupFilter"
                @cancel="testGroupDialog = false"
                @open="testGroupDialog = true"
                @input="onInput()"
                @delete="onInput()" />
        </v-dialog>
        <v-dialog
            v-model="testDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <app-form
                :key="key"
                v-model="editedTest"
                :schema="getSchema('Tests')"
                :filter="testFilter"
                @cancel="testDialog = false"
                @open="testDialog = true"
                @input="onInput()"
                @delete="onInput()" />
        </v-dialog>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        Test Groups
                    </v-col>
                    <v-col>
                        <v-btn
                            v-if="canEdit('TestGroups')"
                            color="primary"
                            class="float-right"
                            dark
                            @click="testGroupDialog = true">
                            Add Test Group
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <div
                            class="align-top justify-top"
                            style="overflow-x: auto; white-space: nowrap">
                            <test-group
                                v-for="testGroup in testGroups"
                                :key="testGroup.id"
                                :test-group="testGroup"
                                :tests="testGroup.items"
                                @move="onMove"
                                @add="onAdd"
                                @edit="onEdit" />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios"
import bucketPageService from "@/services/bucketPageService"

export default {
    components: {
        appForm: () => import('@/components/AppForm'),
        testGroup: () => import('./TestGroup')
    },
    props: {
        filter: {
            type: Object,
            default: null
        },
        refreshKey: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            testGroups: [],

            editedTestGroup: null,
            testGroupFilter: {},
            testGroupDialog: false,
            key: 0,

            editedTest: null,
            testFilter: {},
            testDialog: false
        }
    },
    watch: {
        $route() {
            this.load();
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            this.testGroups = await bucketPageService.getTestGroupsWithTests();
        },
        async onMove(event) {
            let test = event.test;
            test.testGroupId = event.testGroupId;

            try {
                await axios.put("/api/Tests", test);
            }
            finally {
                await this.load();
            }
        },
        onAdd(testGroupId) {
            this.editedTest = null;
            this.editedTestGroup = null;
            this.key++;
            this.testFilter = { ...this.filter, testGroupId };
            this.testDialog = true;
        },
        onEdit(test) {
            this.editedTest = test;
            this.testDialog = true;
        },
        async onInput() {
            this.editedTest = null;
            this.editedTestGroup = null;
            this.testGroupDialog = false;
            this.testDialog = false;
            this.key++;
            await this.load();
        }
    }
};
</script>
